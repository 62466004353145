import React, { useState, } from "react";
import { Button, Stack } from "react-bootstrap"
import { timeUnit } from "../../../app/enums"

interface IHeartbeatProps {
    handleClose: any;
    handleHeartbeatFilter: any;
    selectedHeartbeatDescription: any;
}

export default function HeartbeatFilter(props: IHeartbeatProps) {

    const [selectedDescription, setSelectedDescription] = useState(props.selectedHeartbeatDescription);

    let heartBeattimerData: { time: number, timeUnit: timeUnit, description: string }[] = [
        { "time": 30, "timeUnit": timeUnit.minute, "description": "Last 30 minutes" },
        { "time": 1, "timeUnit": timeUnit.hour, "description": "Last hour" },
        { "time": 4, "timeUnit": timeUnit.hour, "description": "Last 4 hours" },
        { "time": 12, "timeUnit": timeUnit.hour, "description": "Last 12 hours" },
        { "time": 1, "timeUnit": timeUnit.day, "description": "Last day" },
        { "time": 3, "timeUnit": timeUnit.day, "description": "Last 3 days" },
        { "time": 7, "timeUnit": timeUnit.day, "description": "Last week" },
        { "time": 14, "timeUnit": timeUnit.day, "description": "Last 2 weeks" },
        { "time": 1, "timeUnit": timeUnit.month, "description": "Last month" }
    ];
    
    const [selectedDate, setSelectedDate] = useState(
        calculateDateFromDescription(props.selectedHeartbeatDescription)
    );

    function calculateDateTime(timeData : any) {
        var heartbeatDate = new Date();

        switch (timeData.timeUnit) {
            case timeUnit.minute:
                heartbeatDate.setMinutes(heartbeatDate.getMinutes() - timeData.time);
                break;
            case timeUnit.hour:
                heartbeatDate.setHours(heartbeatDate.getHours() - timeData.time);
                break;
            case timeUnit.day:
                heartbeatDate.setDate(heartbeatDate.getDate() - timeData.time);
                break;
            case timeUnit.month:
                heartbeatDate.setMonth(heartbeatDate.getMonth() - timeData.time);
                break;
            default:
                throw new Error('Time unit invalid!');
        }
        
        setSelectedDescription(timeData.description);
        setSelectedDate(heartbeatDate.toISOString());
    }    

    function calculateDateFromDescription(description: string){
        let timeData = heartBeattimerData.find(item => item.description === description);
        var heartbeatDate = new Date();
        if (timeData) {
            switch (timeData.timeUnit) {
                case timeUnit.minute:
                    heartbeatDate.setMinutes(heartbeatDate.getMinutes() - timeData.time);
                    break;
                case timeUnit.hour:
                    heartbeatDate.setHours(heartbeatDate.getHours() - timeData.time);
                    break;
                case timeUnit.day:
                    heartbeatDate.setDate(heartbeatDate.getDate() - timeData.time);
                    break;
                case timeUnit.month:
                    heartbeatDate.setMonth(heartbeatDate.getMonth() - timeData.time);
                    break;
                default:
                    throw new Error('Time unit invalid!');
            }
        }
        return heartbeatDate.toISOString();
    }

    function onClose() {
        try {
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
    }

    function handleApply() {
        try {
            let heartbeatObj = { dateHeartbeat: selectedDate, description: selectedDescription };
            props.handleHeartbeatFilter(heartbeatObj);
            props.handleClose();

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <div id="establishmentFilterTable">
                <Stack>
                    {heartBeattimerData.map((data, index) =>
                    <form>
                        <Stack direction="horizontal" className="mt-2">
                                <input id={`heartbeat-${index}`} type="radio" name="heartbeat" className="cursor-pointer" 
                                    checked={selectedDescription === data.description} onChange={() => calculateDateTime(data)} /> 
                                <label htmlFor={`heartbeat-${index}`} className="ms-2 cursor-pointer">{data.description}</label>
                        </Stack>
                        </form>
                    )}
                </Stack>
            </div>
            <div className="mt-4">
                <Button
                    variant="popup-btn btn-outline-secondary"
                    style={{ width: '49%' }}
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="popup-btn ms-1 btn btn-primary crbc-bg-color"
                    style={{ width: '49%' }}
                    className={` ${selectedDescription ? "" : "disabled"}`}
                    onClick={() => handleApply()}
                >
                    Apply
                </Button>
            </div>
        </React.Fragment>
    )
}