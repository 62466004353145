import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseButton, Dropdown, Stack } from "react-bootstrap";
import CustomModal from "../../../components/modal";
import { useEffect, useRef, useState } from "react";
import EstablishmentProductsList from "./establishment-products";
import EstablishmentAvailableProductsList from "./establishment-available-products";
import EstablishmentInstalledProductsList from "./establishment-installed-products";
import HeartbeatFilter from "./heartbeat-filter";
import { DeviceFilterType } from "../../../app/enums";
import LastUpdateAttemptFilter from "./update-attempt-filter";

const ProductListHeader = () => {
    return (
        <Stack>
            <p className="heading">Products</p>
            <p className="text-muted">Please select a Product</p>
        </Stack>
    )
}

const InstalledProductListHeader = () => {
    return (
        <Stack>
            <p className="heading">Version Installed</p>
            <p className="text-muted">Please select a Version</p>
        </Stack>
    )
}

const AvailableProductListHeader = () => {
    return (
        <Stack>
            <p className="heading">Version Available</p>
            <p className="text-muted">Please select a Version</p>
        </Stack>
    )
}

const HeartbeatHeader = () => {
    return (
        <Stack>
            <p className="heading">Filter by Heartbeat</p>
            <p className="text-muted">Please select a Time Range</p>
        </Stack>
    )
}

const LastUpdateAttemptHeader = () => {
    return (
        <Stack>
            <p className="heading">Filter by Last Update Attempt</p>
            <p className="text-muted">Please select a Time Range</p>
        </Stack>
    )
}

interface IFilterProps {
    value: string;
    type: string
}

export default function DeviceFilter(props: any) {
    const [filterList, setFilterList] = useState<IFilterProps[]>([]);

    const [showProductModal, setShowProductModal] = useState(false);
    const [showAvailableProductModal, setShowAvailableProductModal] = useState(false);
    const [showInstalledProductModal, setShowInstalledProductModal] = useState(false);
    const [showHeartbeatModal, setShowHeartbeatModal] = useState(false);
    const [showLastUpdateAttemptModal, setShowLastUpdateAttemptModal] = useState(false);


    const handleCloseProductModal = () => setShowProductModal(false);
    const handleCloseAvailableProductModal = () => setShowAvailableProductModal(false);
    const handleCloseInstalledProductModal = () => setShowInstalledProductModal(false);
    const handleCloseHeartbeatModal = () => setShowHeartbeatModal(false);
    const handleCloseLastUpdateAttemptModal = () => setShowLastUpdateAttemptModal(false);

    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedAvailableProduct, setSelectedAvailableProduct] = useState('');
    const [selectedInstalledProduct, setSelectedInstalledProduct] = useState('');
    const [selectedHeartbeat, setSelectedHeartbeat] = useState('');
    const [selectedLastUpdateAttempt, setSelectedLastUpdateAttempt] = useState('');

    const hasRun = useRef(false);
    useEffect(() => {
        if(!hasRun.current)
        {
            hasRun.current = true;
            setupLastMonthHeartbeatFilter();
        }
    // eslint-disable-next-line
    }, []);

    function removeFilter(type : any) {
        const index = filterList.findIndex(obj => obj.type === type);
        if (index !== -1) {
            filterList.splice(index, 1);
        }
    }

    function selectProductFilter(product: any) {
        removeFilter(DeviceFilterType.Product);
        let filter = {value : product.productName, type: DeviceFilterType.Product} as IFilterProps;
        setFilterList((prev) => [...prev, filter]);
        props.callbackProduct(product.productId);
        setSelectedProduct(product);
    }

    function selectAvailableVersionFilter(availableVersion: any) {
        removeFilter(DeviceFilterType.AvailableVersion);
        let filter = {value : availableVersion, type: DeviceFilterType.AvailableVersion} as IFilterProps;
        setFilterList((prev) => [...prev, filter]);
        props.callbackAvailableVersion(availableVersion);
        setSelectedAvailableProduct(availableVersion);
    }

    function selectInstalledVersionFilter(installedVersion: any) {
        removeFilter(DeviceFilterType.InstalledVersion);
        let filter = {value : installedVersion, type: DeviceFilterType.InstalledVersion} as IFilterProps;
        setFilterList((prev) => [...prev, filter]);
        props.callbackInstalledVersion(installedVersion);
        setSelectedInstalledProduct(installedVersion);
    }

    function selectHeartbeatFilter(heartbeatObj: any) {
        removeFilter(DeviceFilterType.DateHeartbeat);
        let filter = {value : heartbeatObj.description, type: DeviceFilterType.DateHeartbeat} as IFilterProps;
        setFilterList((prev) => [...prev, filter]);
        props.callbackHeartbeat(heartbeatObj.dateHeartbeat);
        setSelectedHeartbeat(heartbeatObj.description);
    }

    function selectLastUpdateAttemptFilter(lastUpdateAttemptObj: any) {
        removeFilter(DeviceFilterType.DateLastUpdateAttempt);
        let filter = {value : lastUpdateAttemptObj.description, type: DeviceFilterType.DateLastUpdateAttempt} as IFilterProps;
        setFilterList((prev) => [...prev, filter]);
        props.callbackLastUpdateAttempt(lastUpdateAttemptObj.dateLastUpdateAttempt);
        setSelectedLastUpdateAttempt(lastUpdateAttemptObj.description);
    }

    const handleRemoveFilter = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const data = event.target.id;
        let newFilterList = [...filterList];
        newFilterList = filterList.filter((el) => {
            return el.type !== data;
        });
        setFilterList(newFilterList);
        if (data === DeviceFilterType.Product) {
            props.callbackProduct('');
            setSelectedProduct('');
        }
        else if (data === DeviceFilterType.AvailableVersion) {
            props.callbackAvailableVersion('');
            setSelectedAvailableProduct('');
        }
        else if (data === DeviceFilterType.InstalledVersion) {
            props.callbackInstalledVersion('');
            setSelectedInstalledProduct('');
        }
        else if (data === DeviceFilterType.DateLastUpdateAttempt) {
            props.callbackLastUpdateAttempt('');
            setSelectedLastUpdateAttempt('');
        }
        else if (data === DeviceFilterType.DateHeartbeat) {
            props.callbackHeartbeat('');
            setSelectedHeartbeat('');
        }
    };

    const handleShowFilter = (event : any) => {
        const data = event.target.id;

        if (data === DeviceFilterType.Product) {
            setShowProductModal(true);
        }
        else if (data === DeviceFilterType.AvailableVersion) {
            setShowAvailableProductModal(true);
        }
        else if (data === DeviceFilterType.InstalledVersion) {
            setShowInstalledProductModal(true);
        }
        else if (data === DeviceFilterType.DateHeartbeat) {
            setShowHeartbeatModal(true);
        }
        else if (data === DeviceFilterType.DateLastUpdateAttempt) {
            setShowLastUpdateAttemptModal(true);
        }
    }

    const setupLastMonthHeartbeatFilter = () => {
        var heartbeatDate = new Date();
        heartbeatDate.setMonth(heartbeatDate.getMonth() - 1);
        const heartbeatObj = { dateHeartbeat: heartbeatDate.toISOString(), description: "Last month"};
        selectHeartbeatFilter(heartbeatObj);    
    }

    return (
        <div className="ms-3 mt-3">
            <Stack direction="horizontal" gap={3}>
                <Dropdown align={"start"}>
                    <Dropdown.Toggle variant="light" className="dropdown-filter" style={{ fontSize: 16 }}>
                        <FontAwesomeIcon icon={faFilter} /> Filters
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="timer-dropdown">
                        <Dropdown.Item onClick={() => setShowProductModal(true)} className="mb-1 font-14 border-0">Product</Dropdown.Item>
                        <Dropdown.Item onClick={() => setShowInstalledProductModal(true)} className="mb-1 font-14 border-0">Version Installed</Dropdown.Item>
                        <Dropdown.Item onClick={() => setShowAvailableProductModal(true)} className="mb-1 font-14 border-0">Version Available</Dropdown.Item>
                        <Dropdown.Item onClick={() => setShowLastUpdateAttemptModal(true)} className="mb-1 font-14 border-0">Last Update Attempt</Dropdown.Item>
                        <Dropdown.Item onClick={() => setShowHeartbeatModal(true)} className="mb-1 font-14 border-0">Heartbeat</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {filterList.map((element, index) => {
                    return (
                        <div key={index} id={element.value}>
                            <div className="btn btn-light" id={element.value}>
                                {
                                    <div id={element.type} className="filter-wrap d-flex align-items-center" onClick={handleShowFilter}>{element.value} <CloseButton id={element.type} className="ms-2 p-0" onClick={handleRemoveFilter} /></div>
                                }
                            </div>
                        </div>
                    );
                })}
            </Stack>

            <CustomModal isShow={showProductModal} handleClose={handleCloseProductModal} header={<ProductListHeader />} size="lg">
                <EstablishmentProductsList handleClose={handleCloseProductModal} handleProductFilter={selectProductFilter} selectedProduct={selectedProduct} />
            </CustomModal>

            <CustomModal isShow={showAvailableProductModal} handleClose={handleCloseAvailableProductModal} header={<AvailableProductListHeader />} size="lg">
                <EstablishmentAvailableProductsList handleClose={handleCloseAvailableProductModal} handleAvailableProductFilter={selectAvailableVersionFilter} selectedAvailableProduct={selectedAvailableProduct}  />
            </CustomModal>

            <CustomModal isShow={showInstalledProductModal} handleClose={handleCloseInstalledProductModal} header={<InstalledProductListHeader />} size="lg">
                <EstablishmentInstalledProductsList handleClose={handleCloseInstalledProductModal} handleInstalledProductFilter={selectInstalledVersionFilter} selectedInstalledProduct={selectedInstalledProduct}/>
            </CustomModal>

            <CustomModal isShow={showHeartbeatModal} handleClose={handleCloseHeartbeatModal} header={<HeartbeatHeader />} size="md">
                <HeartbeatFilter handleClose={handleCloseHeartbeatModal} handleHeartbeatFilter={selectHeartbeatFilter} selectedHeartbeatDescription={selectedHeartbeat} />
            </CustomModal>

            <CustomModal isShow={showLastUpdateAttemptModal} handleClose={handleCloseLastUpdateAttemptModal} header={<LastUpdateAttemptHeader />} size="md">
                <LastUpdateAttemptFilter handleClose={handleCloseLastUpdateAttemptModal} handleLastUpdateAttemptFilter={selectLastUpdateAttemptFilter} selectedLastUpdateAttemptDescription={selectedLastUpdateAttempt} />
            </CustomModal>
        </div>
    );
}