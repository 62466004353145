import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, CloseButton, ListGroup, Stack } from "react-bootstrap";
import { EstablishmentFilterType, EstablishmentStatus } from "../../app/enums";
import CustomModal from "../modal";
import HubButton from "../button";

interface IFilterProps {
    callbackStatus: any;
}

interface IFilterListProps {
    type: any;
    key: any;
    value: any;
}

export default function FilterEstablishment(props: IFilterProps) {
    const [FilterList, setFilterList] = useState<IFilterListProps[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    
    const [selectedStatusValue, setSelectedStatusValue] = useState<number>(1);

    const handleCloseStatusModal = () => setShowStatusModal(false);

    const StatusOptions = [
        { value: "Good", key: EstablishmentStatus.Good },
        { value: "Warning", key: EstablishmentStatus.Warning },
        { value: "Error", key: EstablishmentStatus.Error },
        { value: "Critical", key: EstablishmentStatus.Critical }
    ];

    const handleValueChange = (event: any) => {
        event.preventDefault();
        const data = event.currentTarget.id;
        const existingFilter = FilterList.find(item => item.type === data);
        if (data === EstablishmentFilterType.Status) {
            if(existingFilter){
                setSelectedStatusValue(parseInt(existingFilter.key));
            }
            setShowStatusModal(true);
        }
    
        setIsOpen(false);
    };

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        const filterType = event.currentTarget.id;
        setFilterList(prev => prev.filter(item => item.type !== filterType));
        
        if (filterType === EstablishmentFilterType.Status) {
            setSelectedStatusValue(1);
            props.callbackStatus(null);
        }
    };    

    //This method is generic, use for all filters
    const handleSelectFilter = (eventKey: number, filterType: EstablishmentFilterType) => {
        if (!eventKey) return;
    
        const existingFilterIndex = FilterList.findIndex(item => item.type === filterType);
    
        if (existingFilterIndex !== -1) {
            setFilterList(prev => {
                const updatedFilters = [...prev];
                updatedFilters[existingFilterIndex] = {
                    ...updatedFilters[existingFilterIndex],
                    key: eventKey.toString(),
                    value: StatusOptions.find(option => option.key === eventKey)?.value || ''
                };
                return updatedFilters;
            });
        } else {
            setFilterList(prev => [
                ...prev,
                { type: filterType.toString(), key: eventKey.toString(), value: StatusOptions.find(option => option.key === eventKey)?.value || '' }
            ]);
        }
    
        if(filterType === EstablishmentFilterType.Status){
            props.callbackStatus(eventKey);
        }
    };

    const StatusModal = () => {
        const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedStatusValue(Number(event.target.value));
        };

        return (
            <Stack>
                <ListGroup className="list-group-sm checklist-filter mt-2">
                    {StatusOptions.map((option) => (
                        <div key={option.key} className="form-check ps-0 mb-2 d-flex align-items-center">
                            <input
                                type="radio"
                                className="form-check-input m-0 me-2 cursor-pointer"
                                id={`opt-${option.key}`}
                                name="statusOptions"
                                value={option.key}
                                checked={selectedStatusValue === option.key}
                                onChange={handleClick}
                            />
                            <label className="form-check-label cursor-pointer" htmlFor={`opt-${option.key}`}>
                                <span style={{ fontSize: "16px" }}>{option.value}</span>
                            </label>
                        </div>
                    ))}
                </ListGroup>
                <Stack direction="horizontal" className="mt-4">
                    <HubButton
                        text="Cancel"
                        className="btn btn-outline-secondary w-50 me-3"
                        type='reset'
                        FnOnClick={() => setShowStatusModal(false)}
                    />
                    <HubButton
                        text="Apply"
                        className="btn btn-primary crbc-bg-color crbc-border-color w-50"
                        FnOnClick={() => {
                            handleSelectFilter(selectedStatusValue, EstablishmentFilterType.Status);
                            setShowStatusModal(false);
                        }}
                    />
                </Stack>
            </Stack>
        );
    }

    return (
        <>
        <Stack direction="horizontal" gap={3} className="w-75">
                <Button className="btn btn-light" onClick={() => { setIsOpen(!isOpen) }} ><FontAwesomeIcon icon={faFilter} /> Filter</Button>
                {FilterList?.map((element, index) => {
                    return (
                        <div key={index} id={element.type}>
                            <div className="btn btn-light" id={element.type}>
                                <div className="filter-wrap d-flex align-items-center font-14"> <div id={element.type} onClick={handleValueChange}>
                                    {element.value}
                                </div><CloseButton id={element.type} value={element.value} className="ms-2 p-0" onClick={handleCancel} /></div>
                            </div>
                        </div>
                    );
                })}
                {isOpen && <div className="filter__dropdown" style={{marginTop: "5.5rem"}}>
                    <div className="margin-left10">
                        <option className="filter-option mb-1" id={EstablishmentFilterType.Status} onClick={handleValueChange}>Status</option>
                    </div>
                </div>}
            </Stack>
            
            <CustomModal isShow={showStatusModal} handleClose={handleCloseStatusModal} header={"Status"} size="md">
                <StatusModal />
            </CustomModal>
        
        </>
    );
}