import { faLongArrowDown, faLongArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const SortButton = ({ options, onSelect }: any) => {

    const handleSelect = (eventKey: any) => {
        const selected = options.find((option: any) => option.value === eventKey);
        onSelect(selected);
    };

    return (
        <DropdownButton
            id="dropdown-basic-button"
            title={<span><FontAwesomeIcon icon={faLongArrowUp} size="2xs" /><FontAwesomeIcon icon={faLongArrowDown} size="2xs" /></span>}
            onSelect={handleSelect}
            variant='btn-outline-secondary'
            className="secondary sort-box-left rounded"

        >
            {options.map((option: any) => (
                <Dropdown.Item key={option.value} eventKey={option.value}>
                    {option.label}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
};

export default SortButton;
