import BaseService from "./BaseService";

export default class AlertService extends BaseService {
    controllerName = "alerts";

    async GetAll(page: number, pageSize: number,): Promise<any> {
        const resp = await this.Get("", {page: page, pageSize: pageSize});
        return resp.data ? resp.data : resp;
    }

    async DeleteAlert(alertID: number): Promise<any> {
        const resp = await this.Delete(`${alertID}`);
        return resp.data ? resp.data : resp;
    }

    async SaveAlert(alertRequest: any): Promise<any> {
        const resp = await this.Post("", alertRequest);
        return resp.data ? resp.data : resp;
    }

     async GetById(alertID: number): Promise<any> {
        const resp = await this.Get(`${alertID}`,{});
        return resp ? resp.data : resp;
    }
    async UpdateAlert(alertID: number ,alertRequest: any): Promise<any> {
        const resp = await this.Put(`${alertID}`, alertRequest);
        return resp.data ? resp.data : resp;
    }

    async GetAllWithChildern(
    alertID: number ,
    includeDisabled: boolean,
    page: number,
    pageSize: number,
    search: string = '',
    sortType:number = 3
  ): Promise<any> {
    const resp = await this.Get(`${alertID}/establishments/all`, {
      alertID:alertID,
      includeDisabled: includeDisabled,
      page: page,
      pageSize: pageSize,
      search: search,
      sortType:sortType
    });

    return resp ? resp.data : null;
  }
 
}