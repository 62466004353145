import React, { useState } from "react";
import PagedFilterTable from "../table";
import { createColumnHelper } from "@tanstack/react-table";
import "bootstrap/dist/css/bootstrap.css";
import { ServiceManager } from "../../services/ServiceManager";
import { Button } from "react-bootstrap";
import IEstablishmentDevice from "../../models/EstablishmentDevice";
import { AddUrlFilter } from "../../app/common/helper/MessageFilter"

interface IDeviceFilterListProps {
    handleClose: any;
    field: any;
    StartDate: any;
    EndDate: any;
    pageIndex: number;
    pageSize: number;
    establishmentId: any;
    handleDeviceFilter: any;
    handleApplyFilter: any;
    selectedDevice: any;
}
export default function DeviceFilterList(props: IDeviceFilterListProps) {
    const [rowSelection, setRowSelection] = useState(props.selectedDevice);
    const [deviceName, setDeviceName] = useState(null);
    const [tableJobs, setTableJobs] = useState([]);
    const columnHelper = createColumnHelper<IEstablishmentDevice>();
    const columns = [
        columnHelper.display({
            id: "select_deviceID",
            cell: props => <input type="checkbox" checked={rowSelection === props.row.original.deviceID} onChange={(e) => { onRowClick(props.row); }}></input>
        }),
        columnHelper.accessor('deviceName', {
            header: () => <span>Name</span>,
            size: 50
        }),
        columnHelper.accessor('deviceID', {
            header: () => <span>ID</span>,
            size: 50,
            cell: ({ row }) => (
                <div >
                    <span >{row.original.deviceID}</span>
                </div>
            ),
        })
    ];

    function onRowClick(row: any) {
        const selectedValue = row.original.deviceID;
        setRowSelection((prevValue: any) => (prevValue === selectedValue ? null : selectedValue));
        setDeviceName((prevValue) => (prevValue === row.original.deviceName ? null : row.original.deviceName));
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string;
    }) {
        const establishmentResponse = await ServiceManager.EstablishmentService.GetDevicesById(props.establishmentId, options.pageIndex + 1, options.pageSize);
        setTableJobs(establishmentResponse.data);
        return establishmentResponse.data;
    }

    function onClose() {
        try {
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
    }

    function handleApply() {
        try {
            props.handleDeviceFilter(props.establishmentId, rowSelection, deviceName);
            props.handleClose();
            props.handleApplyFilter();
            AddUrlFilter("deviceId", rowSelection);
            AddUrlFilter("deviceName", deviceName);

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <PagedFilterTable
                tableKey={"devices-filter"}
                columns={columns}
                data={tableJobs}
                usePagination={true}
                useSearch={false}
                pageRecords={25}
                fnFetchData={fetchData}
            />
            <div className="mt-4">
                <Button
                    variant="popup-btn right-margin10 btn-outline-secondary"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="popup-btn btn btn-primary crbc-bg-color"
                    className={` ${rowSelection === null ? "disabled" : ""}`}
                    onClick={() => handleApply()}
                >
                    Apply
                </Button>
            </div>
        </React.Fragment>
    )
};