import React, { useState } from "react";
import { Stack, Card } from "react-bootstrap";
import { ServiceManager } from "../../services/ServiceManager";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import Select from "react-select";
import { GUID } from "../../app/common/helper/Guid";
import { APIStatusCode, Permissions } from "../../app/enums";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import HUBSpinner from "../../components/spinner";

export interface IEstablishmentsRing {
    establishmentId?: any;
    ringId?: any;
    ringName?: any;
    navigateTo: string;
    isParent: any;
}

export default function EstablishmentsRing(props: IEstablishmentsRing) {

    const CanAssignPrivateRings = usePermissionCheck(Permissions.CanAssignPrivateRings);
    const CanAssignPublicRings = usePermissionCheck(Permissions.CanAssignPublicRings);
    const queryClient = useQueryClient();

    const [assinedRing, setAssinedRing] = useState<any>({
        value: props.ringName,
        label: props.ringName,
        ringID: props.ringId,
    });

    const { isLoading, data : allRings } = useQuery([`establishmentRing-${props.establishmentId}`],
        () =>
            ServiceManager.ProductService.GetAllRings(),
        {
            refetchOnWindowFocus: false
        }
    );

    const mutation = useMutation({
        mutationFn: (ring: any) => {
            return ServiceManager.EstablishmentService.AssignRing(ring.establishmentId, ring.ringId);
        },
        onError: (error, variables, context) => {
        },
        onSuccess: (data, variables, context) => {
            if (data == null) {
                ServiceManager.ToastServer.showError("Unable to assing ring");
            }
            else {

                if (data.status === APIStatusCode.NoContent) {
                    ServiceManager.ToastServer.showSuccess("Ring assigned successfully");
                    const ringData = allRings?.data?.filter((r : any) => r.ringID === variables.ringId).at(0);
                    setAssinedRing({
                        value: ringData.ringName,
                        label: ringData.ringName,
                        ringID: ringData.ringId,
                    });
                    queryClient.invalidateQueries({ queryKey: ["establishmentDetail-" + props.establishmentId] });
                }
                else {
                    let errorMessage = data.errors.errors;
                    ServiceManager.ToastServer.showError(errorMessage.toString());
                }
            }
        }
    });

    function handleChange(option: any) {
        let establishmentId = props.establishmentId as GUID
        let ringId = option?.ringID;
        var ring = { establishmentId: establishmentId, ringId: ringId }
        mutation.mutate(ring);
    }

    const ringListOptions = () => {
        let productRingArray: any[] = [];
        if (!isLoading) {
            allRings?.data?.forEach((ring: any) => {
                productRingArray.push({
                    value: ring.ringName,
                    label: ring.ringName,
                    ringID: ring.ringID,
                    isPublic: ring.isPublic,
                });
            });
        }
        return productRingArray.filter((ring) =>
            (CanAssignPublicRings && CanAssignPrivateRings && ring)
            || (CanAssignPublicRings && ring.isPublic)
            || (CanAssignPrivateRings && !ring.isPublic)
        )
    };

    const customStyles = {
        control: (baseStyles: any) => ({
            ...baseStyles,
            borderRadius: '8px',
            borderColor: '#D0D5DD'
        }),
    }

    return (
        <React.Fragment>
            <div className="client">
                <Card style={{ height: '10em' }}>
                    <Card.Body className="d-flex flex-column justify-content-between">
                        <Stack direction="horizontal">
                            <Card.Title as="h6">Ring</Card.Title>
                            <Link className="establishment-messages-widget-box-header-button" to={props.navigateTo}>
                                View
                            </Link>
                        </Stack>
                        <div className="role-column d-flex mt-4 m-auto w-100 establishment-messages-log-count-header" >Assign Ring</div>
                        <div className="role-column d-flex mt-2 justify-content-center m-auto w-100">
                            {mutation.isLoading ?
                                (
                                    <HUBSpinner color="crbc-color" />
                                ) : (
                                    <Select className="ringbox-border w-100"
                                        options={ringListOptions()}
                                        value={assinedRing}
                                        onChange={(option) => handleChange(option)}
                                        styles={customStyles}
                                        isDisabled={(!CanAssignPublicRings && !CanAssignPrivateRings) || props.isParent != null}
                                    />
                                )}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>

    );
}