import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Stack } from "react-bootstrap";
import { IDeviceSorting } from "../../../models/EstablishmentDevice";
import { useState } from "react";
import { DeviceSortingEnum, SortOrder } from "../../../app/enums";

interface IDeviceSortingProps {
    callbackSorting: any,
    isSortingDisabled: boolean
}

export default function DeviceSorting(props: IDeviceSortingProps) {
    const [sorting, setSorting] = useState<IDeviceSorting | null>(null);

    const handleSorting = (sortBy: string, sortOrder: string) => {
        if (sorting && sorting.sortBy === sortBy && sorting.sortOrder === sortOrder) {
            return;
        }

        let sortingObj: IDeviceSorting = {
            sortBy: sortBy,
            sortOrder: sortOrder
        };
        setSorting(sortingObj);
        props.callbackSorting(sortingObj);
    }

    return (
        <div className="mt-3">
            <Stack direction="horizontal" gap={3}>
                <Dropdown align={"end"} className={props.isSortingDisabled ? 'disabled' : ''}>
                    <Dropdown.Toggle variant="light" className="dropdown-sort" style={{ fontSize: 16 }} disabled={props.isSortingDisabled}>
                        <FontAwesomeIcon icon={faSort} style={{ color: sorting !== null ? '#0047AB' : '' }}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="timer-dropdown">
                    <Dropdown.Item 
                        onClick={() => handleSorting(DeviceSortingEnum.DeviceName, SortOrder.ASC)} 
                        className={`mb-1 font-14 border-0 ${sorting?.sortBy === DeviceSortingEnum.DeviceName && sorting.sortOrder === SortOrder.ASC ? "active" : ""}`}
                    >
                        Devices A {'>'} Z
                    </Dropdown.Item>
                    <Dropdown.Item 
                        onClick={() => handleSorting(DeviceSortingEnum.DeviceName, SortOrder.DESC)} 
                        className={`mb-1 font-14 border-0 ${sorting?.sortBy === DeviceSortingEnum.DeviceName && sorting.sortOrder === SortOrder.DESC ? "active" : ""}`}
                    >
                        Devices Z {'>'} A
                    </Dropdown.Item>
                    <Dropdown.Item 
                        onClick={() => handleSorting(DeviceSortingEnum.DateHeartbeat, SortOrder.DESC)} 
                        className={`mb-1 font-14 border-0 ${sorting?.sortBy === DeviceSortingEnum.DateHeartbeat && sorting.sortOrder === SortOrder.DESC ? "active" : ""}`}
                    >
                        Heartbeat New {'>'} Old
                    </Dropdown.Item>
                    <Dropdown.Item 
                        onClick={() => handleSorting(DeviceSortingEnum.DateHeartbeat, SortOrder.ASC)} 
                        className={`mb-1 font-14 border-0 ${sorting?.sortBy === DeviceSortingEnum.DateHeartbeat && sorting.sortOrder === SortOrder.ASC ? "active" : ""}`}
                    >
                        Heartbeat Old {'>'} New
                    </Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
            </Stack>
        </div>
    )
}