export enum APIStatusCode {
    Ok = 200,
    Created = 201,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409,
}
export enum LogLevelName {
    Critical = 'Critical',
    Error = 'Error',
    Warning = 'Warning',
}


export enum LogLevel {
    Trace = 0,
    Debug = 1,
    Information = 2,
    Warning = 3,
    Error = 4,
    Critical = 5,
    None = 6,
}
export enum LogLevelStyles {
    "log-warning" = 3,
    "log-error" = 4,
    "log-critical" = 5,
    "log-information"=2,
    "log-debug" = 1,
    "log-trace" = 0
}

export enum LogdotStyles {
    "dot-warning" = 3,
    "dot-error" = 4,
    "dot-critical" = 5,
    "dot-information"=2,
    "dot-debug" = 1,
    "dot-trace" = 0
}
export enum timeUnit {
    minute = 'minute',
    day = 'day',
    hour = 'hour',
    month = 'month',
    year = 'year'
}
export enum Permissions {
    CanEditEstablishments = "canEditEstablishments",
    CanViewEstablishments = "canViewEstablishments",
    CanEditProducts = "canEditProducts",
    CanViewProducts = "canViewProducts",
    CanAssignProducts = "canAssignProducts",
    CanManageRoles = "canManageRoles",
    CanAssignRoles = "canAssignRoles",
    CanViewUsers = "canViewUsers",
    CanManageRings = "canManageRings",
    CanAssignPrivateRings = "canAssignPrivateRings",
    CanAssignPublicRings = "canAssignPublicRings",
    CanViewRings = "canViewRings",
    CanManageDevices = "canManageDevices",
    CanManageAlerts = "canManageAlerts"
}

export enum DeviceFilterType {
    Product = "Product",
    AvailableVersion = "AvailableVersion",
    InstalledVersion = "InstalledVersion",
    DateHeartbeat = "DateHeartbeat",
    DateLastUpdateAttempt = "DateLastUpdateAttempt"
}

export enum MessageFilterType {
    Establishment = "Establishment",
    Device = "Device",
    Product = "Product",
    Package = "Package",
    DateTime = "Date/Time",
    LogLevel = "Log Level",
    Category = "Category",
    MessageId = "MessageId"
}

export enum SortOrder {
    ASC = "ASC",
    DESC = "DESC",
}

export enum AlertType {
    Unknown = 0,
    LogLevel = 1,
    LogText = 2,
    DeviceHeartbeat = 3,
    SoftwareHeartbeat = 4,
    VersionMismatch = 5,
    DuplicateDevice = 6,
    IPAddressChange = 7
}

export enum AlertFieldType {
    Unknown = 0,
    LogLevel = 1,
    Count = 2,
    TimeFrame = 3,
    LogText = 4,
    Time = 5,
} 

export enum DeviceSortingEnum {
    DeviceName = "DeviceName",
    DateHeartbeat = "DateHeartbeat"
}

export enum EstablishmentFilterType {
    Status = "Status"
}

export enum EstablishmentStatus {
    None = 0,
    Good = 1,
    Warning = 2,
    Error = 3,
    Critical = 4
}

export enum EstablishmentStatusStyles {
    "log-information"=1,
    "log-warning" = 2,
    "log-error" = 3,
    "log-critical" =4,
}